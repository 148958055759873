import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MiniCard = makeShortcode("MiniCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Welcome!`}</h2>
    <p>{`Please select your relevant integration:`}</p>
    <MiniCard title="Quickrisk" href="/quickrisk-hu/getting-started" actionIcon="arrowRight" mdxType="MiniCard" />
    <MiniCard title="Sales Partner" href="/sp-api-hu/getting-started" actionIcon="arrowRight" mdxType="MiniCard" />
    <MiniCard title="PastPay" href="/pastpay-en/getting-started" actionIcon="arrowRight" mdxType="MiniCard" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      